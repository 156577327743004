const skills = [
  {
    title: 'Languages',
    items: [
      'Java',
      'Python',
      'JavaScript',
      'TypeScript',
      'C',
      'Go',
      'Ruby',
      'OCaml',
      'Racket',
      'Rust',
      'SQL',
      'GraphQL',
      'HTML',
      'CSS',
    ],
  },
  {
    title: 'Frameworks',
    items: [
      'Spring Boot',
      'JUnit',
      'TestNG',
      'Spark',
      'React',
      'Next.js',
      'Node.js',
      'Express',
      'tRPC',
      'React Native',
      'Expo',
      'Tailwind CSS',
    ],
  },
  {
    title: 'Developer Tools',
    items: [
      'Git',
      'GitHub',
      'Unix',
      'Maven',
      'Docker',
      'Kubernetes',
      'Jenkins',
      'MongoDB',
      'Visual Studio Code',
      'IntelliJ IDEA',
      'Jupyter Notebook',
      'Jira',
    ],
  },
];

export default skills;
